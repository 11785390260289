@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,900);@import url(https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed:400,500,700);@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
#app {
  font-family: 'Barlow Semi Condensed', sans-serif;
  margin: 0;
  overflow: hidden;
}
.channel-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 20px 0;
  cursor: pointer;
  height: 84px;
}
.channel-list img {
  width: 94px;
  height: 34px;
  border: 2px solid #EEE;
  border-radius: 10px;
  margin: 0 10px;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
.channel-list img:hover.main {
  border: 2px solid #008DCB;
}
.channel-list img:hover.proc {
  border: 2px solid #b0151e;
}
.channel-list img:hover.dare {
  border: 2px solid #78408e;
}
.channel-list img:hover.int {
  border: 2px solid #a9a8a8;
}
.channel-list img:hover.kids {
  border: 2px solid #fff200;
}
.channel-list img:hover.lat {
  border: 2px solid #ef5e2f;
}
.channel-list img:hover.radio {
  border: 2px solid #009999;
}
.channel-list .selected {
  width: 124px;
  height: 44px;
  border: 2px solid #444;
  border-radius: 10px;
}
.channel-list .selected.main {
    border: 2px solid #008DCB;
}
.channel-list .selected.proc {
    border: 2px solid #b0151e;
}
.channel-list .selected.dare {
    border: 2px solid #78408e;
}
.channel-list .selected.int {
    border: 2px solid #a9a8a8;
}
.channel-list .selected.kids {
    border: 2px solid #fff200;
}
.channel-list .selected.lat {
    border: 2px solid #ef5e2f;
}
.channel-list .selected.radio {
    border: 2px solid #009999;
}
.days-cont {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: auto;
}
.day-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100px;
}
@media (max-width: 502px) {
.day-list {
      height: 42px;
}
}
.day-list .day {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 60px;
            flex: 1 0 60px;
    padding: 20px 10px;
    /* margin-right: 60px */
    color: #888;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;
    cursor: pointer;
}
@media (max-width: 502px) {
.day-list .day {
        padding: 0 6px;
}
}
.day-list .day .name {
      font-size: 28px;
      font-weight: 700;
}
@media (max-width: 502px) {
.day-list .day .name {
          font-size: 20px;
}
}
.day-list .day .date {
      font-size: 16px;
      font-weight: 400;
}
@media (max-width: 502px) {
.day-list .day .date {
          font-size: 12px;
}
}
.day-list .day.main.selected {
      color: #FFF;
      background: -webkit-gradient(linear, left bottom, left top, from(#0057a4), to(#008DCB));
      background: linear-gradient(to top, #0057a4 0%, #008DCB 100%);
}
.day-list .day.main.selected:hover {
        color: #FFF;
}
.day-list .day.main:hover {
      color: #008DCB;
}
.day-list .day.proc.selected {
      color: #FFF;
      background: -webkit-gradient(linear, left bottom, left top, from(#860a12), to(#b0151e));
      background: linear-gradient(to top, #860a12 0%, #b0151e 100%);
}
.day-list .day.proc.selected:hover {
        color: #FFF;
}
.day-list .day.proc:hover {
      color: #b0151e;
}
.day-list .day.dare.selected {
      color: #FFF;
      background: -webkit-gradient(linear, left bottom, left top, from(#3c1d52), to(#78408e));
      background: linear-gradient(to top, #3c1d52 0%, #78408e 100%);
}
.day-list .day.dare.selected:hover {
        color: #FFF;
}
.day-list .day.dare:hover {
      color: #78408e;
}
.day-list .day.int.selected {
      color: #FFF;
      background: -webkit-gradient(linear, left bottom, left top, from(#666666), to(#a9a8a8));
      background: linear-gradient(to top, #666666 0%, #a9a8a8 100%);
}
.day-list .day.int.selected:hover {
        color: #FFF;
}
.day-list .day.int:hover {
      color: #a9a8a8;
}
.day-list .day.kids.selected {
      color: #444;
      background: -webkit-gradient(linear, left bottom, left top, from(#fed32f), to(#fff200));
      background: linear-gradient(to top, #fed32f 0%, #fff200 100%);
}
.day-list .day.kids.selected:hover {
        color: #444;
}
.day-list .day.kids:hover {
      color: #fff200;
}
.day-list .day.lat.selected {
      color: #FFF;
      background: -webkit-gradient(linear, left bottom, left top, from(#cb412d), to(#ef5e2f));
      background: linear-gradient(to top, #cb412d 0%, #ef5e2f 100%);
}
.day-list .day.lat.selected:hover {
        color: #FFF;
}
.day-list .day.lat:hover {
      color: #ef5e2f;
}
.day-list .day.radio.selected {
      color: #FFF;
      background: -webkit-gradient(linear, left bottom, left top, from(#006d6c), to(#009999));
      background: linear-gradient(to top, #006d6c 0%, #009999 100%);
}
.day-list .day.radio.selected:hover {
        color: #FFF;
}
.day-list .day.radio:hover {
      color: #009999;
}
.day-list .arrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20px;
            flex: 0 0 20px;
    padding: 20px;
    color: #FFF;
    font-size: 32px;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;
}
@media (max-width: 502px) {
.day-list .arrow {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 10px;
                flex: 0 0 10px;
        padding: 10px;
}
}
.day-list .arrow.main {
      background: -webkit-gradient(linear, left bottom, left top, from(#0057a4), to(#008DCB));
      background: linear-gradient(to top, #0057a4 0%, #008DCB 100%);
}
.day-list .arrow.main:hover {
        background: #0057a4;
}
.day-list .arrow.proc {
      background: -webkit-gradient(linear, left bottom, left top, from(#860a12), to(#b0151e));
      background: linear-gradient(to top, #860a12 0%, #b0151e 100%);
}
.day-list .arrow.proc:hover {
        background: #860a12;
}
.day-list .arrow.dare {
      background: -webkit-gradient(linear, left bottom, left top, from(#3c1d52), to(#78408e));
      background: linear-gradient(to top, #3c1d52 0%, #78408e 100%);
}
.day-list .arrow.dare:hover {
        background: #3c1d52;
}
.day-list .arrow.int {
      background: -webkit-gradient(linear, left bottom, left top, from(#666666), to(#a9a8a8));
      background: linear-gradient(to top, #666666 0%, #a9a8a8 100%);
}
.day-list .arrow.int:hover {
        background: #666666;
}
.day-list .arrow.kids {
      background: -webkit-gradient(linear, left bottom, left top, from(#fed32f), to(#fff200));
      background: linear-gradient(to top, #fed32f 0%, #fff200 100%);
      color: #444;
}
.day-list .arrow.kids:hover {
        background: #fed32f;
}
.day-list .arrow.lat {
      background: -webkit-gradient(linear, left bottom, left top, from(#cb412d), to(#ef5e2f));
      background: linear-gradient(to top, #cb412d 0%, #ef5e2f 100%);
}
.day-list .arrow.lat:hover {
        background: #cb412d;
}
.day-list .arrow.radio {
      background: -webkit-gradient(linear, left bottom, left top, from(#006d6c), to(#009999));
      background: linear-gradient(to top, #006d6c 0%, #009999 100%);
}
.day-list .arrow.radio:hover {
        background: #006d6c;
}
.day-list .arrow.left {
      cursor: pointer;
}
.day-list .arrow.right {
      margin-right: 0;
}
#program-list {
  overflow: auto;
  height: 1000px;
}
#program-list.radio .time {
  font-size: 18px;
}
#program-list.radio .info .title {
  font-size: 20px;
}
#program-list.radio .info .desc {
  line-height: 1;
}
.sched-program {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  border-bottom: 2px solid #EEE;
}
@media (max-width: 502px) {
.sched-program {
      padding: 10px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
}
}
.sched-program .time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 120px;
          flex: 0 0 120px;
  font-size: 22px;
  font-weight: 500;
}
@media (max-width: 502px) {
.sched-program .time {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      font-size: 16px;
      -webkit-box-align: stretch;
          -ms-flex-align: stretch;
              align-items: stretch;
      color: #0057a4;
}
}
.sched-program .img {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 210px;
          flex: 0 0 210px;
}
@media (max-width: 768px) {
.sched-program .img {
      display: none;
}
}
.sched-program .img img {
  height: 100px;
  padding: 0 14px;
}
.sched-program .info {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-weight: 500;
  line-height: 1.2;
}
.sched-program .info .title {
  font-size: 24px;
  font-weight: 500;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
@media (max-width: 502px) {
.sched-program .info .title {
      font-size: 18px;
}
}
.sched-program .info .title .series.main {
    color: #008DCB;
}
.sched-program .info .title .series.proc {
    color: #b0151e;
}
.sched-program .info .title .series.dare {
    color: #78408e;
}
.sched-program .info .title .series.int {
    color: #000000;
}
.sched-program .info .title .series.kids {
    color: #fbb315;
}
.sched-program .info .title .series.lat {
    color: #ef5e2f;
}
.sched-program .info .title .series.radio {
    color: #009999;
}
.sched-program .info .title .program {
    color: #555;
}
.sched-program .info .title .bar {
    color: #BBB;
}
.sched-program .info .desc {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.sched-program .info .misc {
  color: #555;
}
@media (max-width: 502px) {
.sched-program .info .misc {
      display: none;
}
}
.channel-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  padding: 0 10px;
  height: 50px;
}
@media (max-width: 502px) {
.channel-bar {
      height: 30px;
}
}
.channel-bar .channel {
    font-weight: 600;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    font-size: 24px;
}
@media (max-width: 502px) {
.channel-bar .channel {
        font-weight: 600;
        font-size: 18px;
}
}
.channel-bar.main {
    background: -webkit-gradient(linear, left bottom, left top, from(#0057a4), to(#008DCB));
    background: linear-gradient(to top, #0057a4 0%, #008DCB 100%);
}
.channel-bar.proc {
    background: -webkit-gradient(linear, left bottom, left top, from(#860a12), to(#b0151e));
    background: linear-gradient(to top, #860a12 0%, #b0151e 100%);
}
.channel-bar.dare {
    background: -webkit-gradient(linear, left bottom, left top, from(#3c1d52), to(#78408e));
    background: linear-gradient(to top, #3c1d52 0%, #78408e 100%);
}
.channel-bar.int {
    background: -webkit-gradient(linear, left bottom, left top, from(#666666), to(#a9a8a8));
    background: linear-gradient(to top, #666666 0%, #a9a8a8 100%);
}
.channel-bar.kids {
    background: -webkit-gradient(linear, left bottom, left top, from(#fed32f), to(#fff200));
    background: linear-gradient(to top, #fed32f 0%, #fff200 100%);
    color: #444;
}
.channel-bar.lat {
    background: -webkit-gradient(linear, left bottom, left top, from(#cb412d), to(#ef5e2f));
    background: linear-gradient(to top, #cb412d 0%, #ef5e2f 100%);
}
.channel-bar.radio {
    background: -webkit-gradient(linear, left bottom, left top, from(#006d6c), to(#009999));
    background: linear-gradient(to top, #006d6c 0%, #009999 100%);
}
.watch-now {
  height: 40px;
  width: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 10px;
  padding: 0 10px;
  text-align: center;
}
.watch-now.main {
    background: -webkit-gradient(linear, left bottom, left top, from(#0057a4), to(#008DCB));
    background: linear-gradient(to top, #0057a4 0%, #008DCB 100%);
}
.watch-now.proc {
    background: -webkit-gradient(linear, left bottom, left top, from(#860a12), to(#b0151e));
    background: linear-gradient(to top, #860a12 0%, #b0151e 100%);
}
.watch-now.dare {
    background: -webkit-gradient(linear, left bottom, left top, from(#3c1d52), to(#78408e));
    background: linear-gradient(to top, #3c1d52 0%, #78408e 100%);
}
.watch-now.int {
    background: -webkit-gradient(linear, left bottom, left top, from(#666666), to(#a9a8a8));
    background: linear-gradient(to top, #666666 0%, #a9a8a8 100%);
}
.watch-now.kids {
    background: -webkit-gradient(linear, left bottom, left top, from(#fed32f), to(#fff200));
    background: linear-gradient(to top, #fed32f 0%, #fff200 100%);
}
.watch-now.kids a {
      color: #444 !important;
}
.watch-now.lat {
    background: -webkit-gradient(linear, left bottom, left top, from(#cb412d), to(#ef5e2f));
    background: linear-gradient(to top, #cb412d 0%, #ef5e2f 100%);
}
.watch-now.radio {
    background: -webkit-gradient(linear, left bottom, left top, from(#006d6c), to(#009999));
    background: linear-gradient(to top, #006d6c 0%, #009999 100%);
}
.watch-now a {
  color: #FFF !important;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
}
@media (max-width: 502px) {
.watch-now a {
      font-size: 16px;
}
.watch-now a i {
        padding-left: 10px;
}
}
